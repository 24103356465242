/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {

    * {
        /* Text rendering */
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;

        /* Remove the focus ring */
        &:focus {
            outline: none !important;
        }
    }

    /* HTML and Body default styles */
    html,
    body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        min-height: 100%;
        transition: background-color .5s cubic-bezier(.25, .8, .25, 1), color .5s cubic-bezier(.25, .8, .25, 1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: auto;
    }

    /* Font size */
    html {
        font-size: 16px;
    }

    body {
        font-size: 0.875rem;
    }

    /* Stylistic alternates for Inter */
    body {
        /*font-feature-settings: 'salt';*/
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6, {
        @apply text-black;
        @apply dark:text-white;
    }

    h1, h2, h3,
    .h1, .h2, .h3 {
        @apply font-sans;
    }

    h4, h5, h6,
    .h4, .h5, .h6 {
        @apply font-sans;
    }

    h1, .h1 {
        @apply font-medium;
        @apply text-3xl;
        @apply leading-8;
    }

    h2, .h2 {
        @apply text-2xl;
        @apply leading-7;
    }

    h3, .h3 {
        @apply font-medium;
        @apply text-lg;
        @apply leading-5;
    }

    h4, .h4 {
        @apply font-medium;
        @apply text-base;
        @apply leading-4;
    }

    h5, .h5 {
        @apply font-medium;
        @apply text-sm;
        @apply leading-4;
    }

    p {
        @apply font-sans;
        @apply text-base;
        @apply leading-5;
    }

    pre {
        @apply font-mono;
    }

    /* Better spacing and border for horizontal rule */
    hr {
        margin: 32px 0;
        border-bottom-width: 1px;
    }

    /* Make images and videos to take up all the available space */
    img {
        width: 100%;
        vertical-align: top;
    }

    /* Fix: Disabled placeholder color is too faded on Safari */
    input[disabled] {
        opacity: 1;
        -webkit-text-fill-color: currentColor;
    }

    body, .dark, .light {
        @apply text-default bg-default #{'!important'};
    }

    *, *::before, *::after {
        --tw-border-opacity: 1 !important;
        border-color: rgba(var(--hemro-border-rgb), var(--tw-border-opacity));

        .dark & {
            --tw-border-opacity: 0.12 !important;
        }
    }

    [disabled] * {
        @apply text-disabled #{'!important'};
    }

    /* Print styles */
    @media print {

        /* Make the base font size smaller for print so everything is scaled nicely */
        html {
            font-size: 12px !important;
        }

        body, .dark, .light {
            background: none !important;
        }
    }
}

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
