@use "./node_modules/@yukawa/chain-base-angular-comp/assets/styles/splash-screen" with (
    $background-class: bg-hemro-non-white,
    $spinner-color: #A89688,
    $text-class: text-hemro-black,
    $logo-color-class: bg-hemro-black,
    $log-background-class: bg-primary-400,
    $log-header-background-class: bg-primary-600,
);
@use "./node_modules/@yukawa/chain-base-angular-comp/assets/styles/confirmation-dialog";
@use "./node_modules/@yukawa/chain-base-angular-comp/assets/styles/query-table";

yukawa-query-table {
    body.theme-brand & {
        mat-header-row, mat-paginator {
            @apply bg-hemro-table-header #{'!important'};
        }
    }

    body.theme-brand.dark & {
        mat-header-row, mat-paginator {
            @apply bg-card #{'!important'};
        }
    }

    body:not(.theme-brand) & {
        mat-header-row, mat-paginator {
            @apply bg-primary-300 #{'!important'};
        }
    }

    body:not(.theme-brand).dark & {
        mat-header-row, mat-paginator {
            @apply bg-card #{'!important'};
        }
    }
}

@import "./node_modules/@yukawa/chain-base-angular-comp/assets/styles/alert.component";

yukawa-splash-screen {
    #app-logo {
        background-color: transparent;
        background-image: url("/assets/icons/logo.svg");
        width: 10rem;
        height: 10rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        mask: none;
        margin-bottom: 1rem;
    }
}
